@font-face {
    font-family: "MuseoSans";
    src: url("./Resources/MuseoSans/MuseoSans300.woff2") format('woff');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSans";
    src: url("./Resources/MuseoSans/MuseoSans500.woff2") format('woff');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: "MuseoSans";
    src: url("./Resources/MuseoSans/MuseoSans700.woff2") format('woff');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: "MuseoSans";
    src: url("./Resources/MuseoSans/MuseoSans900.woff2") format('woff');
    font-weight: 900;
    font-style: normal;
  }